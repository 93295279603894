import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  routes: (routes) => {
    const landingPageSuffixes = ['at', 'ch', 'de', 'group', 'hotel']

    return routes.flatMap((route) => {
      if (route.name === 'landing-page') {
        return landingPageSuffixes.map((suffix) => {
          return {
            ...route,
            name: 'landing-page-' + suffix,
            // Will be overriden by vuepal's static node page feature.
            path: '/landing-page-' + suffix,
            meta: {
              ...route.meta,
              staticDrupalPage: 'landing_page_' + suffix,
            },
          }
        })
      }

      return route
    })
  },
}
