import { datadogLogs } from '@datadog/browser-logs'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin({
  name: 'datadog-init',
  setup({ $config }) {
    const { datadogToken } = $config.public

    if (datadogToken && typeof datadogToken === 'string') {
      datadogLogs.init({
        clientToken: datadogToken,
        service: 'nuxt',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        env: ($config.public.environment as string) || '',
      })
    }
  },
})
