import { default as indexm7A2sbkHbNMeta } from "/builds/bazinga/sv-group/frontend/pages/[...slug]/index.vue?macro=true";
import { default as _91id_937rFlda62EiMeta } from "/builds/bazinga/sv-group/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93p3hejvZIX7Meta } from "/builds/bazinga/sv-group/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexdVG76RGAx9Meta } from "/builds/bazinga/sv-group/frontend/pages/index.vue?macro=true";
import { default as chSFRw0UMUiPMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/ch.vue?macro=true";
import { default as decIjDfBbOTkMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/de.vue?macro=true";
import { default as groupDCnjjePtFbMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/group.vue?macro=true";
import { default as hotelvOvgVYep8EMeta } from "/builds/bazinga/sv-group/frontend/pages/landing-page/hotel.vue?macro=true";
import { default as indexwfUu3or7s8Meta } from "/builds/bazinga/sv-group/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as indexOYDGwgE0ylMeta } from "/builds/bazinga/sv-group/frontend/pages/press-release/[...slug]/index.vue?macro=true";
import { default as indexOGAVhFd3UlMeta } from "/builds/bazinga/sv-group/frontend/pages/search/index.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexm7A2sbkHbNMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "webform-route",
    path: "/form/:id()",
    meta: _91id_937rFlda62EiMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/form/[id].vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_93p3hejvZIX7Meta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexdVG76RGAx9Meta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/index.vue")
  },
  {
    name: "landing-page-ch",
    path: "/landing-page/ch",
    meta: chSFRw0UMUiPMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/ch.vue")
  },
  {
    name: "landing-page-de",
    path: "/landing-page/de",
    meta: decIjDfBbOTkMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/de.vue")
  },
  {
    name: "landing-page-group",
    path: "/landing-page/group",
    meta: groupDCnjjePtFbMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/group.vue")
  },
  {
    name: "landing-page-hotel",
    path: "/landing-page/hotel",
    meta: hotelvOvgVYep8EMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/landing-page/hotel.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexwfUu3or7s8Meta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "press-release-detail",
    path: "/press-release/:slug(.*)*",
    meta: indexOYDGwgE0ylMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/press-release/[...slug]/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexOGAVhFd3UlMeta || {},
    component: () => import("/builds/bazinga/sv-group/frontend/pages/search/index.vue")
  }
]